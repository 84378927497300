<template>
  <div class="body-page">
    <!-- <div class="header fixed w-full z-10"> -->
    <div
      class="header fixed w-full z-10 transition-all duration-500 ease-in-out overflow-hidden md:overflow-visible"
      :class="{
        ' bg-transparant py-5': route.name === 'beranda' && !scrollStatus && clientWidth > 1024,
        ' bg-primary shadow-2xl': route.name !== 'beranda' || scrollStatus || clientWidth < 1024,
        ' max-h-[4.2rem]': !menuToggleStatus && clientWidth < 1024,
        ' max-h-full': menuToggleStatus,
      }"
    >
      <div class="w-[92%] md:w-[88%] xl:w-4/5 mx-auto">
        <VaNavbar color="transparent" class="navbar-home mb-3 sm:mb-0">
          <template #left>
            <div class="flex gap-2 cursor-pointer items-center">
              <VaNavbarItem class="logo">
                <Image class="w-12 m-auto" :src="logoDisnaker" fit="contain" />
              </VaNavbarItem>
              <p class="text-3xl text-white">DTKTE</p>
            </div>
          </template>
          <template #center>
            <VaNavbarItem
              class="menu-item-nav hidden md:block text-white hover:text-gray-300 cursor-pointer"
              :class="{ active: route.name === 'beranda' }"
            >
              <p class="h-full flex items-center" @click="jumpTo('beranda')">Beranda</p>
            </VaNavbarItem>
            <VaNavbarItem
              class="menu-item-nav hidden md:block text-white hover:text-gray-300 cursor-pointer"
              :class="{ active: route.name === 'daftar-pengumuman' }"
            >
              <p class="h-full flex items-center" @click="jumpTo('daftar-pengumuman')">Pengumuman</p>
            </VaNavbarItem>
            <VaNavbarItem
              class="menu-item-nav hidden md:block text-white hover:text-gray-300 cursor-pointer"
              :class="{ active: route.name === 'daftar-pelatihan' }"
            >
              <p class="h-full flex items-center" @click="jumpTo('daftar-pelatihan')">Pelatihan</p>
            </VaNavbarItem>
            <!-- <VaNavbarItem
              class="menu-item-nav hidden md:block text-white hover:text-gray-300 cursor-pointer"
              :class="{ active: route.name === 'daftar-galeri' }"
            >
              <p class="h-full flex items-center" @click="jumpTo('daftar-galeri')">Galeri</p>
            </VaNavbarItem> -->
            <VaNavbarItem class="hidden md:flex md:gap-5 ml-3">
              <span class="text-white font-bold hover:text-gray-300 cursor-pointer" @click="jumpTo(`masuk`)"
                >Masuk</span
              >
              <button
                class="btn-daftar py-2 px-5 rounded-lg font-bold text-primary bg-white hover:bg-gray-300 shadow-lg transition duration-150 ease-in-out"
                @click="jumpTo(`daftar`)"
              >
                Daftar
              </button>
            </VaNavbarItem>
          </template>
          <template #right>
            <VaNavbarItem class="ml-3 hidden md:block">
              <div class="relative">
                <div
                  class="rounded-lg absolute transition-all duration-500 ease-in-out"
                  :class="{
                    '-top-12 right-0 ': route.name === 'beranda' && !scrollStatus,
                    '-top-10 right-0': route.name !== 'beranda' || scrollStatus,
                  }"
                >
                  <svg width="80" height="100" viewBox="0 0 200 250" xmlns="http://www.w3.org/2000/svg">
                    <!-- Bentuk perisai dengan background putih -->
                    <path d="M0 0 L200 0 L200 200 Q100 300 0 200 Z" fill="white" />

                    <!-- Gambar yang diunggah ditempatkan di atas background putih -->
                    <image :href="logoSuksesJakarta" x="5" y="15" height="210px" width="190px" />
                  </svg>
                  <!-- <Image class="w-20 m-auto absolute top-0 left-0" :src="logoSuksesJakarta" fit="contain" /> -->
                </div>
              </div>
            </VaNavbarItem>
            <VaNavbarItem class="block md:hidden text-white hover:text-gray-300 cursor-pointer">
              <!-- <VaIcon
                class="animate__animated animate__fadeIn"
                :name="menuToggleStatus ? 'mso-close' : 'mso-menu'"
                :size="29"
                @click="showMenu"
              /> -->
              <VaIcon
                v-show="!menuToggleStatus"
                class="animate__animated animate__fadeIn"
                :name="'mso-menu'"
                :size="29"
                @click="showMenu"
              />
              <VaIcon
                v-show="menuToggleStatus"
                class="animate__animated animate__fadeIn"
                :name="'mso-close'"
                :size="29"
                @click="showMenu"
              />
            </VaNavbarItem>
          </template>
        </VaNavbar>
        <div class="mobile-menu p-3 bg-white rounded-xl mx-2 mb-5 relative">
          <!-- <div class="rounded-lg absolute transition-all duration-500 ease-in-out -top-1 right-3 z-[1]">
            <svg width="80" height="100" viewBox="0 0 200 250" xmlns="http://www.w3.org/2000/svg">
              Bentuk perisai dengan background putih
              <path d="M0 0 L200 0 L200 200 Q100 300 0 200 Z" stroke="black" stroke-width="1" fill="white" />

              Gambar yang diunggah ditempatkan di atas background putih
              <image :href="logoSuksesJakarta" x="5" y="15" height="210px" width="190px" />
            </svg>
            <Image class="w-20 m-auto absolute top-0 left-0" :src="logoSuksesJakarta" fit="contain" />
          </div> -->
          <div class="grid grid-cols-12 gap-5">
            <div class="col-span-8 sm:col-span-10">
              <VaMenuList class="w-[100%] mb-4">
                <VaMenuItem class="!block py-2 w-full focus:text-primary" @click="jumpTo('beranda')">
                  Beranda
                </VaMenuItem>
                <VaMenuItem class="!block py-2 w-full focus:text-primary" @click="jumpTo('daftar-pengumuman')">
                  Pengumuman
                </VaMenuItem>
                <VaMenuItem class="!block py-2 w-full focus:text-primary" @click="jumpTo('daftar-pelatihan')">
                  Pelatihan
                </VaMenuItem>
              </VaMenuList>
            </div>
            <div class="col-span-4 sm:col-span-2">
              <div class="flex items-center h-full p-2">
                <Image class="w-full m-auto" :src="logoSuksesJakarta" fit="contain" />
              </div>
            </div>
          </div>
          <div>
            <button
              class="w-full border-2 border-primary block mb-2 py-2 px-5 rounded-lg font-bold text-primary transition duration-150 ease-in-out"
              @click="jumpTo(`masuk`)"
            >
              Masuk
            </button>
            <button
              class="block w-full bg-primary text-white py-2 px-5 rounded-lg font-bold text-primary shadow-xl transition duration-150 ease-in-out"
              @click="jumpTo(`daftar`)"
            >
              Daftar
            </button>
          </div>
        </div>
      </div>
    </div>

    <RouterView v-slot="{ Component }">
      <component :is="Component"></component>
    </RouterView>

    <div class="footer bg-white">
      <div class="w-4/5 mx-auto">
        <div class="first-footer pb-6 pt-12">
          <div class="grid grid-cols-12 gap-y-8 lg:gap-y-0">
            <div class="col-span-12 lg:col-span-5 lg:pr-12">
              <div class="flex gap-0 items-center justify-center sm:justify-start">
                <div class="logo">
                  <Image class="drop-shadow-md w-24 sm:w-32 m-auto" :src="logoDisnaker" fit="contain" />
                </div>
                <p class="text-lg sm:text-2xl font-bold">
                  Dinas Tenaga Kerja, Transmigasi dan Energi Provinsi DKI Jakarta
                </p>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-6 lg:col-span-3">
              <div>
                <h3 class="text-lg font-bold">Kontak</h3>
                <hr class="w-10 border border-primary bg-primary h-[3px] rounded-full mb-5" />
                <ul>
                  <li class="mb-1 text-base">021-3848303</li>
                </ul>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-7 md:col-span-6 lg:col-span-4">
              <div>
                <h3 class="text-lg font-bold">Alamat</h3>
                <hr class="w-10 border border-primary bg-primary h-[3px] rounded-full mb-5" />
                <ul>
                  <li class="mb-1 text-base">Jl. Prajurit KKO Usman dan Harun No.52, RT.7/RW.1</li>
                  <li class="mb-1 text-base">Kelurahan Gambir, Kecamatan Gambir</li>
                  <li class="mb-1 text-base">Kota Jakarta Pusat</li>
                  <li class="mb-1 text-base">Daerah Khusus Ibukota Jakarta</li>
                  <li class="mb-1 text-base">Kode Pos 10110</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr class="h-[3px] bg-slate-200 rounded-xl" />
        <div class="py-5">
          <p class="text-center text-gray-400">Dinas Ketenagakerjaan Transmigrasi dan Energi © 2024.</p>
        </div>
      </div>
    </div>
  </div>

  <!-- <div
    class="animate__animated fixed bottom-5 right-5"
    :class="{ ' animate__zoomIn': scrollStatus, ' animate__zoomOut': !scrollStatus }"
  >
    <div
      v-show="isScrollActive"
      class="shadow-btn-totop rounded-full p-1 bg-primary text-white cursor-pointer transition duration-700 ease-in-out hover:scale-110"
      @click="toTop"
    >
      <VaIcon name="mso-stat_1" :size="50" />
    </div>
  </div> -->
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Image from '../components/Image.vue'
import { logoDisnaker, logoSuksesJakarta } from '../utils/images'

const router = useRouter()
const route = useRoute()

const isScrollActive = ref<boolean>(false)
const scrollStatus = ref<boolean>(false)

const onScrollAction = () => {
  window.onscroll = (e: any) => {
    if (e.target.scrollingElement.scrollTop > 100) {
      isScrollActive.value = true
      scrollStatus.value = true
      return
    }
    scrollStatus.value = false
  }
}

const toTop = () => {
  window.scrollTo({ top: 0 })
}

const clientWidth = ref<number>(0)

const autoResize = () => {
  clientWidth.value = window.innerWidth
  window.addEventListener('resize', (e: any) => {
    clientWidth.value = e.target.innerWidth
  })
}

const menuToggleStatus = ref<boolean>(false)

const jumpTo = (routeName: string) => {
  document.body.classList.remove('show')
  document.body.classList.remove('overlay')
  menuToggleStatus.value = false
  router.push({ name: routeName })
}

const showMenu = () => {
  document.body.classList.add('overlay')
  if (!menuToggleStatus.value) {
    menuToggleStatus.value = true
    document.body.classList.add('show')
    document.body.classList.remove('hide')
    return
  }
  document.body.classList.remove('show')
  document.body.classList.add('hide')
  menuToggleStatus.value = false
}

onMounted(() => {
  onScrollAction()
  autoResize()
})
</script>

<style lang="scss">
.navbar-home .va-navbar__right {
  align-items: center !important;
}

.btn-daftar:hover {
  transform: scale(1.1);
}
.btn-daftar:active {
  transform: scale(1);
}
.mobile-menu {
  display: none;
}
.menu-item-nav {
  @apply relative;
}
.menu-item-nav:before {
  content: '';
  position: absolute;
  z-index: -2;
  left: 0;
  right: 100%;
  bottom: -7px;
  border-radius: 10px;
  background: #fff;
  height: 3px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.menu-item-nav:hover {
  opacity: 1 !important;
  color: rgb(210, 210, 210) !important;
}
.menu-item-nav:hover:before {
  right: 0;
}
.menu-item-nav.active {
  color: rgb(210, 210, 210) !important;
}
.menu-item-nav.active:before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: -7px;
  border-radius: 10px;
  background: #fff;
  height: 3px;
}
// #top-menu li li a:before {
//   bottom: 10%;
// }

@media screen and (max-width: 1024px) {
  .mobile-menu {
    display: block;
  }
}
@media screen and (max-width: 1024px) {
  .navbar-home {
    display: flex;
    padding: 0.8rem 0;
  }
  .navbar-home .va-navbar__center {
    width: 20%;
  }
  .navbar-home .va-navbar__left {
    width: 80%;
  }
}
</style>
