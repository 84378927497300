<template>
  <VaLayout class="hidden md:grid h-full md:h-screen bg-[var(--va-background-secondary)]">
    <template #left>
      <div v-if="breakpoint.lgUp" class="left-content">
        <div class="h-full w-full absolute z-[-5] overflow-hidden">
          <img
            v-for="(item, i) in imagesCarousel"
            :key="i"
            :class="{ show: isShowImage[`${i}`] }"
            class="image"
            :src="item.image"
            alt="Jakarta"
          />
        </div>
        <div class="h-full w-full flex justify-center items-center flex-col gap-4 relative z-10">
          <Image class="max-w-44 max-h-36 m-auto rounded-xl overflow-hidden" :src="logoDisnaker" fit="contain" />
          <div>
            <p class="text-4xl font-bold text-white text-center" style="text-shadow: 0 5px 10px rgba(0, 0, 0, 1)">
              Disnakertransgi <span class="block mt-1">DKI Jakarta</span>
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <main
        :class="{
          'h-full lg:flex lg:flex-row lg:items-center lg:justify-start mx-auto w-full sm:max-w-[500px] px-4':
            !breakpoint.lgUp,
          'h-full w-full overflow-auto': breakpoint.lgUp,
        }"
      >
        <div
          :class="{
            'h-full flex items-center justify-center mx-auto w-full overflow-auto px-4': breakpoint.lgUp,
            'lg:flex lg:flex-row w-full h-full lg:h-auto sm:w-auto lg:items-start': !breakpoint.lgUp,
          }"
        >
          <div v-if="!breakpoint.lgUp" class="flex center mt-3 mb-3">
            <div class="relative z-10 flex items-center">
              <div class="w-28">
                <Image class="w-full h-full rounded-xl overflow-hidden" :src="logoDisnaker" fit="contain" />
              </div>
              <div class="w-full">
                <p class="text-xl font-semibold">Dinas Tenaga Kerja <span class="block">DKI Jakarta</span></p>
              </div>
            </div>
          </div>
          <RouterView v-slot="{ Component }">
            <component :is="Component" class="animate__animated animate__fadeIn"></component>
          </RouterView>
        </div>
        <!-- <RouterView /> -->
      </main>
    </template>
  </VaLayout>

  <div class="block md:hidden bg-[var(--va-background-secondary)]">
        <div
          class="h-full h-screen"
        >
          <div v-if="!breakpoint.lgUp" class=" centermb-3">
            <div class="relative z-10 flex items-center pt-3">
              <div class="w-28">
                <Image class="w-full h-full rounded-xl overflow-hidden" :src="logoDisnaker" fit="contain" />
              </div>
              <div class="w-full">
                <p class="text-xl font-semibold">Dinas Tenaga Kerja <span class="block">DKI Jakarta</span></p>
              </div>
            </div>
          </div>
          <RouterView v-slot="{ Component }">
            <component :is="Component" class="animate__animated animate__fadeIn"></component>
          </RouterView>
        </div>
        <!-- <RouterView /> -->
  </div>

  <!-- <VaLayout v-else class="h-full lg:h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <div class="p-4 h-full">
        <main class="h-full lg:flex lg:flex-row lg:items-center lg:justify-start mx-auto w-full sm:max-w-[500px]">
          <div class="lg:flex lg:flex-row w-full h-full lg:h-auto sm:w-auto lg:items-start">
            <div class="flex center mt-3 mb-3">
              <div class="relative z-10 flex items-center">
                <div class="w-28">
                  <Image class="w-full h-full rounded-xl overflow-hidden" :src="logoDisnaker" fit="contain" />
                </div>
                <div class="w-full">
                  <p class="text-xl font-semibold">Dinas Tenaga Kerja <span class="block">DKI Jakarta</span></p>
                </div>
              </div>
            </div>
            <RouterView v-slot="{ Component }">
              <component :is="Component" class="animate__animated animate__fadeIn"></component>
            </RouterView>
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout> -->
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useBreakpoint } from 'vuestic-ui'
// import VuesticLogo from '../components/VuesticLogo.vue'
import Image from '../components/Image.vue'
import { image1, image2, image4, image7, logoDisnaker } from '../utils/images'

const breakpoint = useBreakpoint()

const imagesCarousel = ref([{ image: image1 }, { image: image2 }, { image: image4 }, { image: image7 }])

type imageStatus = {
  [key: string]: boolean
}

const isShowImage = ref<imageStatus>({})

const carouselFunc = () => {
  let imageIndexAfter = 0
  let imageIndexBefore: any = null

  const changeBackground = () => {
    isShowImage.value[`${imageIndexAfter}`] = true
    imageIndexAfter === 3 ? (imageIndexAfter = 0) : imageIndexAfter++
    if (imageIndexBefore !== null) isShowImage.value[`${imageIndexBefore}`] = false
    imageIndexBefore === null || imageIndexBefore === 3 ? (imageIndexBefore = 0) : imageIndexBefore++
  }

  changeBackground()
  setInterval(changeBackground, 7000)
}

onMounted(() => {
  carouselFunc()
})
</script>

<style scoped>
@keyframes animateImage {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}
.left-content {
  @apply w-[45vw] h-full relative;
}
/* .left-content {
  background: url(../assets/courosel-images/image1.jpg);
  background-size: cover;
  background-position: center;
} */
.left-content::after {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.image {
  @apply h-full w-full object-cover absolute;
}
.image {
  opacity: 0;
  /* transition: none; */
  transform: scale(1.21);
  transition: opacity 1s ease-in-out;
}
.show {
  animation: animateImage 10s linear;
  opacity: 1;
  z-index: -1;
  transition: none;
}
</style>
